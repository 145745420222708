import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Layout from "../components/layout"
import {
  Map,
  Users,
  DollarSign,
  Globe,
  Briefcase,
  Send,
  ArrowRight,
} from "react-feather"
import SEO from "../components/seo"
import WorldMap from "../components/worldmap"

const IndexPage = () => (
  <Layout>
    <SEO title="Start your company, immigrate, and raise money" />
    <div id="landing-top">
      <div id="contains-map">
        <div id="map-container">
          <WorldMap />
        </div>
      </div>

      <div id="left-col">
        <div id="left-col-content">
          <h1 id="full-header">
            Start your company, immigrate, and raise money. In any order.
          </h1>
          <h2 className="landing-description">
            How many Elons are in Africa unable to immigrate? Satyas, Sundars in
            India? Eric Yuans in China? Whatever the probabilities are, it’s a
            numbers game. And the question you should ask yourself is what if
            minor recognition is all it would’ve taken to make them reach their
            potential — sometimes it isn’t even about money or ongoing
            mentorship but recognition by people they admire. That by itself can
            do wonders for someone who’s driven to go far.
          </h2>
        </div>
      </div>
      <div id="right-col">
        <div id="enabler-actions">
          <AniLink fade to="/startup-visas" duration={0.4}>
            <div className="lp-item-container">
              <div className="lp-item-header">
                <div className="item-icon-container">
                  <Map className="header-icon" />
                </div>
                Startup Visas
                <div className="item-arrow">
                  <ArrowRight />
                </div>
              </div>
              <div className="item-description">
                <p>
                  Share your knowledge and experiences with other founders and
                  would-be founders in the same shoes you were earlier in your
                  journey.
                </p>
              </div>
            </div>
          </AniLink>
          {/* <AniLink fade to="/jobs" duration={1}>
          <div className="item-container">
            
            <div className="item-header">
            <div className="item-icon-container">
            <Briefcase className="header-icon" />
            </div>
             Visa Sponsored Jobs
              <div className="item-arrow">
                →
              </div>
            </div>
            <div className="item-description">
              <p>Share your knowledge and experiences with other founders and would-be founders in the same shoes you were earlier in your journey.</p>
            </div>
          </div>
        </AniLink> */}

          {/* <AniLink fade to="/ecosystems" duration={0.4}>
          <div className="long-container">
            
            <div className="item-header">
            <div className="item-icon-container">
            <Globe className="header-icon" />
            </div>
            View Ecosystems
              <div className="item-arrow">
                →
              </div>
            </div>
            <div className="item-description">
              <p>Share your knowledge and experiences with other founders and would-be founders in the same shoes you were earlier in your journey.</p>
            </div>

          </div>
        </AniLink> */}

          {/* <AniLink fade to="/community" duration={0.4}>
          <div className="lp-item-container">
            
            <div className="lp-item-header">
            <div className="item-icon-container">
            <Users className="header-icon" />
            </div>
            Join Community
              <div className="item-arrow">
                →
              </div>
            </div>
            <div className="item-description">
              <p>Share your knowledge and experiences with other founders and would-be founders in the same shoes you were earlier in your journey.</p>
            </div>

          </div>
        </AniLink> */}

          <OutboundLink
            href="https://t.me/joinchat/Jhr2gxL3mambwfZlapQY3A"
            target="_blank"
          >
            <div className="lp-item-container">
              <div className="lp-item-header">
                <div className="item-icon-container">
                  <Send className="header-icon" />
                </div>
                Join Telegram
                <div className="item-arrow">
                  <ArrowRight />
                </div>
              </div>
              <div className="item-description">
                <p>
                  Join our community of founders building startups on Telegram
                  to explore your incorporation and immigration options.
                </p>
              </div>
            </div>
          </OutboundLink>
          <AniLink fade to="/investors" duration={0.4}>
            <div className="lp-item-container">
              <div className="lp-item-header">
                <div className="item-icon-container">
                  <DollarSign className="header-icon" />
                </div>
                Raise Funds
                <div className="item-arrow">
                  <ArrowRight />
                </div>
              </div>
              <div className="item-description">
                <p>
                  Share your knowledge and experiences with other founders and
                  would-be founders in the same shoes you were earlier in your
                  journey.
                </p>
              </div>
            </div>
          </AniLink>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
